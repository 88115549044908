import React from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';

const COLORS = ['#00C49F', '#0088FE'];
const RADIAN = Math.PI / 180;

const ChartPaymentPie = ({ data }) => {

    const { totalCashAmount, totalCardAmount } = data;

    const cash = { name: 'מזומן', value: totalCashAmount };
    const card = { name: 'אשראי', value: totalCardAmount };

    const pieData = [cash, card];

    return (
        <ResponsiveContainer width={350} height="100%" style={{ direction: 'ltr' }}>
            <PieChart width={350} height={100}>
                <Pie
                    data={pieData}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={150}
                    fill="#8884d8"
                    dataKey="value"
                >
                    {pieData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    );

    function renderCustomizedLabel({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) {

        const radius = innerRadius + (outerRadius - innerRadius) * 0.4;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        if(percent < 0.06) return null;
       
        return (
            <text fontSize={'1.25rem'} x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline={"central"}>
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    }
}

export default ChartPaymentPie;