import moment from "moment";
import { IExpiredPermitPageForm, IExpiredPermitPageData } from "../../interface/permit/expiredPermitPage.interface";
import { IPermit } from "../../interface/permit/permit.interface";

export const initExpiredPermitFormData: IExpiredPermitPageForm = {
    limit: 10,
    offset: 0,
    startDate: moment(new Date()).format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
    onlyActivePermits: '0',
}

export const initExpiredPermitData: IExpiredPermitPageData = {
    count: 0,
    permits: [] as IPermit[]
}