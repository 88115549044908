import React, { FC } from 'react';
import ExportToCsvButton from '../../../../Components/Buttons/ExportToCsvButton';
import { IRowData } from '../../../../Components/UI/displayData/Table/Table';

const csvHeader = [
    { displayName: "שם מלא", id: "fullName" },
    { displayName: "מס רכב", id: "vehicleNum" },
    { displayName: "טלפון", id: "cellPhone" },
    { displayName: "מספר טלפון נוסף", id: "additionalPhone" },
    { displayName: "אימייל", id: "email" },
    { displayName: "תאריך סיום", id: "endValidityDate" },
    { displayName: "יצרן", id: "makerName" },
    { displayName: "צבע", id: "colorName" },
    { displayName: "סוג רכב", id: "vehicleGroupName" },
]

type Props = {
    show: boolean,
    onClick: () => Promise<IRowData[]>,
    loading: boolean,
}

const BtnsRow: FC<Props> = ({ show, onClick, loading }) => {

    if (!show) {
        return null;
    }

    return (
        <div className='row'>
            <div className='col'>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginLeft: '1rem' }}>
                    <ExportToCsvButton data={onClick} headers={csvHeader} fileName='expired permit' />
                </div>
            </div>
        </div>
    );
}

export default BtnsRow;