import React from 'react';
import { formaterMoneyAmount } from '../../../../Helpers/formaters/numberFormat';

const TablePie = ({ data }) => {

    const { totalCashNumberOfUnits, totalCreditCardNumberOfUnits,
        totalCashAmount, totalCardAmount } = data; 
    
    return (
        <table className="table table-hover">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">סכום כסף</th>
                    <th scope="col">מספר תנועות</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th scope="row">אשראי</th>
                    <td>{formaterMoneyAmount(totalCardAmount)}</td>
                    <td>{totalCreditCardNumberOfUnits}</td>
                </tr>
                <tr>
                    <th scope="row">מזומן</th>
                    <td>{formaterMoneyAmount(totalCashAmount)}</td>
                    <td>{totalCashNumberOfUnits}</td>

                </tr>
                <tr>
                    <th scope="row">סיכום</th>
                    <td>{formaterMoneyAmount(totalCardAmount + totalCashAmount)}</td>
                    <td>{totalCreditCardNumberOfUnits + totalCashNumberOfUnits}</td>
                </tr>
            </tbody>
        </table>
    );
}

export default TablePie;