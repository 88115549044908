import useAxiosPrivate from "../useAxiosPrivate";
import { useAuthContextContext } from "../../contexts/AuthContext";
import { PermitStatusType, ResponseStatus } from "../../types/types.import";
import { IPermitForm } from "../../interface/permit/permitForm.interface";
import convertJsonToFormData from "../../Helpers/convertJsonToFormData";
import { IPermitReportForm, IPermitReportPageData } from "../../interface/permit/permitReportPage.interface";
import translateErrorCode from "../../Helpers/errors/translateErrorCode";
import { isIPermitInterface } from "../../api/validation/permits/isIPermitInterface";
import { IPermitFilterPage } from "../../interface/permit/permitListPage.interface";
import { addPermitResult, getExpiredPermitsResult, getPermitsResult } from "../../interface/api/permitApi.interface";
import { IExpiredPermitPageForm } from "../../interface/permit/expiredPermitPage.interface";
import { ReactNode } from "react";

const ADD_PERMIT = '/permit/add-permit';
const GET_PERMIT = '/permit';
const UPDATE_PERMIT = '/permit/update-permit';
const GET_PERMIT_REPORT_DETAILS = '/permit/permit-report';
const GET_END_VALIDITY_PERMITS = '/permit/get-end-validity-permits';
const GET_PERMIT_LIST = '/permit/get-permits';

const usePermitApi = () => {

    const { logout } = useAuthContextContext();
    const axiosPrivate = useAxiosPrivate();


    async function addPermitApi(values: IPermitForm): Promise<addPermitResult> {

        const result: addPermitResult = {
            status: ResponseStatus.FAILURE,
            message: '',
            data: { permitId: -1, hasReceipt: false, receiptUrl: '' }
        }

        try {

            const form = convertJsonToFormData(values);

            const response = await axiosPrivate.post(ADD_PERMIT, form);
            const data = response.data;

            // 3. Getting from the http response the response data.
            const permitId = data.permitId ? data.permitId : -1;
            const hasReceipt = data.hasReceipt ? data.hasReceipt : false;
            const receiptUrl = data.receiptUrl ? data.receiptUrl : '';
            
            if(permitId < 0) {

                throw new Error('permitId is invalid');
            }

            result.status = ResponseStatus.SUCCESS;
            result.data.permitId = permitId;
            result.data.hasReceipt = hasReceipt;
            result.data.receiptUrl = receiptUrl;

        } catch (error) {

            let message: string | ReactNode = 'בעיה כללית בשרת';

            if (error.response) {

                if (error?.response?.status === 401) {

                    message = 'צריך להתחבר מחדש 401';
                    logout();
                }

                message = translateErrorCode(error);
            } else if (error.request) {
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }

            result.message = message;
            console.log('error: ' + error);
        }

        return result;
    }

    async function getPermitApi(permitId: number) {

        const result = {
            data: {
                permit: undefined,
                resident: undefined,
                vehicle: undefined
            },
            status: ResponseStatus.FAILURE,
            message: ''
        }

        try {

            const response = await axiosPrivate.get(`${GET_PERMIT}/${permitId}`);
            const data = response?.data?.data;
            const resident = data.permit.resident;
            const vehicle = data.permit.vehicle;

            result.status = data.status;
            result.message = data.message;
            result.data = {
                permit: data.permit,
                resident: resident,
                vehicle: vehicle
            }
        } catch (error) {
            const status = error.response?.status;
            const errorData = error.response?.data;
            let errorMsg = `קרתה שגיעה בשרת קוד שגיעה: ${status}`;

            if (errorData) {

                errorMsg = errorData?.message;
                console.log(`error.message: ${JSON.stringify(errorData?.message)}`)
            }

            console.error(error);
            result.message = errorMsg;
        }

        return result;
    }

    async function updatePermitApi(values: IPermitForm) {

        const result = {
            status: ResponseStatus.FAILURE,
            message: ''
        }

        const form = convertJsonToFormData(values);

        try {

            const response = await axiosPrivate.post(UPDATE_PERMIT, form);

            const data = response.data;

            result.status = data.status;
            result.message = data.message
        } catch (error) {
            console.log('error ' + error);
            result.message = error?.response?.data?.message; //'בעיה כללית בשרת';

            if (error.message === 'permit is valid') {

                result.message = 'קיים תו זהה אשר תוקפו גדול משלושה חודשים';
            }

            if (error.message === 'Network Error') {

                result.message = 'בעיה כללית בשרת קוד: 502';
            }

            if (error?.response?.status === 401) {

                result.message = 'צריך להתחבר מחדש 401';
                logout();
            }
        }

        return result;
    }

    async function getPermitsReportDataApi(json: IPermitReportForm): Promise<IPermitReportPageData> {

        const form = new FormData();
        form.append('fromDate', json.fromDate);
        form.append('toDate', json.toDate);

        const response = await axiosPrivate.post(GET_PERMIT_REPORT_DETAILS, form);
        return response.data;
    }

    async function getExpiredPermitsApi(values: IExpiredPermitPageForm) {

        const result: getExpiredPermitsResult = {
            isSuccess: false,
            message: '',
            data: { count: 0, permits: [] }
        }

        const form = {
            from: values.startDate,
            to: values.endDate,
            itemPerPage: values.limit,
            offset: values.offset,
            onlyActivePermits: values.onlyActivePermits === '1',
        };

        try {

            const response = await axiosPrivate.post(GET_END_VALIDITY_PERMITS, form);
            const data = response.data;

            if (!validateGetExpiredPermitsApi(data)) {

                throw new Error("aMiT kOrEn");
            }

            result.data.count = data.data.count;
            result.data.permits = data.data.permits;
            result.isSuccess = true;
            result.message = 'success';

        } catch (error) {

            result.message = 'בעיה כללית בשרת';
            result.isSuccess = false;

            if (error.response) {

                result.message = translateErrorCode(error);
            } else if (error.request) {
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
        }

        return result;
    }

    async function updateStatusPermitApi(permitId: number, newStatus: PermitStatusType) {

        const result = {
            status: ResponseStatus.FAILURE,
            message: '',
        };

        const form = new FormData();
        form.append("permitId", String(permitId));
        form.append("newStatus", String(newStatus));

        try {

            const response = await axiosPrivate.post(
                '/permit/update-permit-status', form);

            const jsonData = response.data;

            if (jsonData.status === ResponseStatus.SUCCESS) {

                result.status = ResponseStatus.SUCCESS;
                result.message = 'success';
                return result;
            }

            if (jsonData?.message) {

                result.message = jsonData.message;
            } else {

                result.message = 'לא התקבל אישור לשינוי סטטוס התו.';
            }

        } catch (error) {

            result.message = 'בעיה בשרת';

            if (error.message === 'Network Error') {

                result.message = 'בעיה כללית בשרת קוד: 502';
            }
        }

        return result;
    }

    async function getPermitsApi(values: IPermitFilterPage, offset: number, itemPerPage: number, signal = undefined) {

        const controller = new AbortController();
        const withAbortSig = signal ? true : false;

        if (!withAbortSig) {
            signal = controller.signal;
        }

        const result: getPermitsResult = {
            status: ResponseStatus.FAILURE,
            message: 'המידע לא הגיע מהשרת',
            data: {
                permits: [],
                count: 0
            }
        };

        try {

            const response = await axiosPrivate.post(
                GET_PERMIT_LIST,
                { ...values, pageNum: offset, itemPerPage: itemPerPage },
                { signal: signal }
            );

            const jsonData = response.data;

            if (validateGetPermitsApiResponse(jsonData)) {

                result.data.permits = jsonData.data.permits;
                result.data.count = jsonData.data.count;
                result.status = ResponseStatus.SUCCESS;
                result.message = '';
            }

        } catch (error) {

            result.message = 'בעיה כללית בשרת';
            result.status = ResponseStatus.FAILURE;

            if (error.response) {

                result.message = translateErrorCode(error);
            } else if (error.request) {
                console.log(error.request);
            } else { // Something happened in setting up the request that triggered an Error

                result.data.count = 0;
                result.data.permits = [];
                result.status = ResponseStatus.SUCCESS;
                result.message = '';
                console.log('user canceled the request', error.message);
            }
        }

        return result;
    }

    return {
        addPermitApi, updatePermitApi, getPermitApi,
        getPermitsReportDataApi, getExpiredPermitsApi,
        updateStatusPermitApi, getPermitsApi
    };


    // ************************* \\

    function validateGetExpiredPermitsApi(data: any): boolean {

        // Validate top-level structure
        if (
            !data ||
            typeof data !== "object" ||
            typeof data.message !== "string" ||
            typeof data.status !== "string" ||
            typeof data.data !== "object" ||
            typeof data.data.count !== "number" ||
            !Array.isArray(data.data.permits)
        ) {
            return false;
        }

        // Validate each permit
        for (const permit of data.data.permits) {
            if (!isIPermitInterface(permit)) return false;
        }

        return true;
    }

    function validateGetPermitsApiResponse(data: any): boolean {

        // Validate top-level structure
        if (
            !data ||
            typeof data !== "object" ||
            typeof data.data !== "object" ||
            typeof data.data.count !== "number" ||
            !Array.isArray(data.data.permits)
        ) {
            return false;
        }

        // Validate each permit
        for (const permit of data.data.permits) {
            if (!isIPermitInterface(permit)) return false;
        }

        return true;
    }
}


export default usePermitApi;