import React from 'react';
import { useProjectDetailsContext } from '../../../../contexts/ProjectDetailsContext';
import { usePermitsListPageContext } from '../../../../contexts/permit/PermitsListPageContext';
import SearchBtnLoading from '../../../../Components/Buttons/SearchBtnLoading';
import InputGroup from '../../../../Components/Forms/InputGroup';
import DateInput from '../../../../Components/Forms/DateInput';
import SelectInput from '../../../../Components/Forms/select/SelectInput';
import SelectStreet from '../../../../Components/Forms/select/SelectStreet';
import SelectZone from '../../../../Components/Forms/select/SelectZone';
import SelectPermitStatus from '../../../../Components/Forms/select/SelectPermitStatus';
import FormBox from '../../../../Components/Forms/FormBox';
import AdvancedSearchBtn from '../../../../Components/Buttons/AdvancedSearchBtn';
import { useAppInfoContext } from '../../../../contexts/AppInfoContext';
import { AppUIBrackPoint } from '../../../../interface/appInfo.interface';
import SelectVehicleGroup from '../../../../Components/Forms/select/SelectVehicleGroup';
import SelectCarColor from '../../../../Components/Forms/select/SelectCarColor';
import SelectCarMaker from '../../../../Components/Forms/select/SelectCarMaker';

const FormFilterPermitManagement = () => {

    const { screenWidth } = useAppInfoContext();
    const { projectDetails } = useProjectDetailsContext();
    const { Formik, handleChange, componentState, handleOnClickAdvSearch } = usePermitsListPageContext();

    const moblieLayout = (screenWidth === AppUIBrackPoint.XS || screenWidth === AppUIBrackPoint.SM);

    const { setFieldTouched, values, setFieldValue, handleBlur, handleSubmit } = Formik;

    const selectUserNames = [{ name: 'כל המשתמשים', value: '' }];
    projectDetails.listUserName.forEach(userName => {
        selectUserNames.push({ name: userName, value: userName });
    });

    const isAdvancedSearchOpen = values.isAdvancedSearchOpen;

    return (
        <FormBox onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-sx-12 col-md-9">
                    <div className='row'>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                            <InputGroup
                                label={'תעודת זהות'}
                                name={"personId"}
                                value={values.personId}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                            />
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                            <InputGroup
                                label={'מספר רכב'}
                                name={"carNumber"}
                                value={values.carNumber}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                            />
                        </div>
                        <div className="col-xl-2 col-lg-4 col-md-6 col-sm-12">
                            <InputGroup
                                label={'מספר תו'}
                                name={"permitId"}
                                handleBlur={handleBlur}
                                value={values.permitId}
                                handleChange={handleChange}
                            />
                        </div>
                        <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                            <DateInput
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                label='תאריך התחלה'
                                value={values.from}
                                name={"from"}
                            />
                        </div>
                        <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                            <DateInput
                                label={'תאריך סוף'}
                                name={"to"}
                                value={values.to}
                                handleBlur={handleBlur}
                                handleChange={handleChange} />
                        </div>
                    </div>
                </div>
                <div className={`col-xs-3 col-md-3 align-self-end ${moblieLayout ? 'mt-3' : ''}`}>
                    <div className='d-flex justify-content-between'>
                        <AdvancedSearchBtn onClick={handleOnClickAdvSearch} isAdvancedSearchOpen={values.isAdvancedSearchOpen} />
                        <SearchBtnLoading onClick={handleSubmit} isLoading={componentState.isLoading} />
                    </div>
                </div>
            </div>
            {isAdvancedSearchOpen ? (
                <>
                    <div className="row justify-content-center">
                        <div className='col-10'>
                            <hr className="border border-primary border-1 opacity-10" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-2 col-lg-4 col-md-6 col-sm-12">
                            <SelectZone
                                name='zone'
                                value={values.zone}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                            />
                        </div>
                        <div className="col-xl-2 col-lg-4 col-md-6 col-sm-12">
                            <SelectPermitStatus
                                value={values.status}
                                name={"status"}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                required={false}
                            />
                        </div>
                        <div className="col-xl-2 col-lg-4 col-md-6 col-sm-12">
                            <SelectInput
                                name='loginUser'
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                value={values.loginUser}
                                selects={selectUserNames}
                                label='שם היוצר'

                            />
                        </div>
                        <div className="col-xl-2 col-lg-4 col-md-6 col-sm-12">
                            <SelectStreet
                                name={'streetId'}
                                value={values.streetId}
                                required={false}
                                handleChange={(name, value) => setFieldValue('streetId', value)}
                                handleBlur={e => {
                                    handleBlur(e);
                                    setFieldTouched('streetId', true);
                                }}
                            />
                        </div>
                        <div className="col-xl-1 col-lg-4 col-md-6 col-sm-12">
                            <InputGroup
                                label={'מספר בית'}
                                name={'houseNum'}
                                handleBlur={handleBlur}
                                value={values.houseNum}
                                handleChange={handleChange} />
                        </div>
                        <div className="col-xl-1 col-lg-4 col-md-6 col-sm-12">
                            <InputGroup
                                label={'מספר דירה'}
                                name={'appatmentNum'}
                                value={values.appatmentNum}
                                handleBlur={handleBlur}
                                handleChange={handleChange} />
                        </div>
                    </div>
                    <div className='row'>

                        <div className='col-xl-2 col-lg-4 col-md-6 col-sm-12'>
                            <SelectCarColor
                                name='vehicleColorId'
                                value={values.vehicleColorId}
                                handleBlur={handleBlur}
                                handleChange={(name, val) => setFieldValue('vehicleColorId', val)}
                                withEmptyField={true}
                            />
                        </div>

                        <div className='col-xl-2 col-lg-4 col-md-6 col-sm-12'>
                            <SelectCarMaker
                                name='vehicleMakerId'
                                value={values.vehicleMakerId}
                                handleBlur={handleBlur}
                                handleChange={(name, val) => setFieldValue('vehicleMakerId', val)}
                                withEmptyField={true}
                            />
                        </div>

                        <div className='col-xl-2 col-lg-4 col-md-6 col-sm-12'>
                            <SelectVehicleGroup
                                name='vehicleGroupId'
                                value={values.vehicleGroupId}
                                handleBlur={handleBlur}
                                handleChange={(name, val) => setFieldValue('vehicleGroupId', val)}
                                withEmptyField={true}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-2 col-lg-4 col-md-6 col-sm-12">
                            <InputGroup
                                label={'שם פרטי'}
                                name={'firstName'}
                                value={values.firstName}
                                handleBlur={handleBlur}
                                handleChange={handleChange} />
                        </div>
                        <div className="col-xl-2 col-lg-4 col-md-6 col-sm-12">
                            <InputGroup
                                label={'שם משפחה'}
                                name={'lastName'}
                                value={values.lastName}
                                handleBlur={handleBlur}
                                handleChange={handleChange} />
                        </div>
                    </div>
                </>
            ) : ''}
        </FormBox>
    )
}

export default FormFilterPermitManagement
