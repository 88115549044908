import React from 'react';
import BtnsRow from './BtnsRow';
import SimpleCard from '../../../../Components/UI/Card/SimpleCard';
import { IHeaderData, IRowData } from '../../../../Components/UI/displayData/Table/Table';
import ViewPermitBtn from '../../../../Components/Buttons/ViewPermitBtn';
import TableWithPaging from '../../../../Components/UI/displayData/Table/TableWithPaging';
import { useExpiredPermitsPageContext } from '../../../../contexts/permit/ExpiredPermitsPageContext';
import { IPermit } from '../../../../interface/permit/permit.interface';
import moment from 'moment';
import { decodeNCRStringIfNeeded } from '../../../../Helpers/language';
import { formatMobileNumber } from '../../../../Helpers/formaters/mobileNumberFormater';
import { convertColorCodeToText, convertMakerCodeToText, convertVehicleGroupCodeToText } from '../../../../Helpers/conversion/convertorTablesCodeToText';
import { useProjectDetailsContext } from '../../../../contexts/ProjectDetailsContext';

const header: IHeaderData[] = [
    {
        key: 'fullName',
        label: 'שם מלא',
        name: 'fullName',
    },
    {
        key: 'vehicleNum',
        label: 'מספר רכב',
        name: 'vehicleNum',
    },
    {
        key: 'cellPhone',
        label: 'סלולרי',
        name: 'cellPhone',
    }, {
        key: 'additionalPhone',
        label: 'מספר טלפון נוסף',
        name: 'additionalPhone',
    }, {
        key: 'endValidityDate',
        label: 'תאריך סיום',
        name: 'endValidityDate',
    },{
        key: 'email',
        label: 'יצרן',
        name: 'email',
    }, {
        key: 'makerName',
        label: 'יצרן',
        name: 'makerName',
    },{
        key: 'colorName',
        label: 'צבע',
        name: 'colorName',
    },{
        key: 'vehicleGroupName',
        label: 'סוג רכב',
        name: 'vehicleGroupName',
    }, {
        key: 'biewPermitBtn',
        label: 'פעולות',
        name: 'biewPermitBtn',
    }
];


const DisplayExpiredPermits = () => {

    const { projectDetails } = useProjectDetailsContext();
    const { componentState, expiredPermitsPage, getExpiredPermitsForCsv, Formik, changePage } = useExpiredPermitsPageContext();
    const count = expiredPermitsPage.count;
    const list = expiredPermitsPage.permits;
    const values = Formik.values;

    const paigingData = {
        offset: values.offset,
        limit: values.limit,
        totleCount: count,
        changePage: changePage,
        isLoading: componentState.isLoading
    }

    const rows: IRowData[] = convertIpermitsToIRowData(list);

    return (
        <>
            <BtnsRow show={rows.length > 0} onClick={handleGetExpiredPermitsForCsv} loading={false} />
            <div className='row'>
                <SimpleCard>
                    <div className='col'>
                        <TableWithPaging
                            headers={header}
                            rows={rows}
                            name={'תווים'}
                            paigingData={paigingData}
                            handleChengeLimit={Formik.handleChange}
                        />
                    </div>
                </SimpleCard>
            </div>
        </>
    );

    async function handleGetExpiredPermitsForCsv() {

        const res = await getExpiredPermitsForCsv(values);

        return convertIpermitsToIRowData(res);
    }

    function convertIpermitsToIRowData(permits: IPermit[]): IRowData[] {

        const makers = projectDetails.types;
        const colors = projectDetails.colors;
        const vehicleGroups = projectDetails.vehicleGroups;

        const convertedList = permits.map(permit => {

            const id = permit.id;
            const firstName = permit.resident.firstName;
            const lastName = permit.resident.lastName;
            const email = permit.resident.email;
            const cellPhone = formatMobileNumber(permit.resident.cellPhone);
            const additionalPhone = permit.resident.phoneNum;
            const vehicleNum = permit.vehicle.vehicleNum;
            const endValidityDate = permit.endValidityDate;
            const fullName = `${decodeNCRStringIfNeeded(firstName)} ${decodeNCRStringIfNeeded(lastName)}`;
  
            const makerName = convertMakerCodeToText(permit.vehicle.vehicleMake, makers, true);
            const colorName = convertColorCodeToText(permit.vehicle.vehicleColor, colors, true);
            const vehicleGroupName = convertVehicleGroupCodeToText(permit.vehicle.vehicleGroupId, vehicleGroups, true);
            let endValidityDateStr = '';

            if (endValidityDate > 0) {
                endValidityDateStr = moment(new Date(endValidityDate)).format("DD-MM-YYYY");
            }

            const rowData: IRowData = {
                key: `expiredPermit-${id}`,
                fullName: fullName,
                vehicleNum: vehicleNum,
                cellPhone: cellPhone,
                additionalPhone: additionalPhone,
                endValidityDate: endValidityDateStr,
                makerName: makerName,
                colorName: colorName,
                vehicleGroupName: vehicleGroupName,
                email: email,
                biewPermitBtn: <ViewPermitBtn rowDbId={id} />,
            };

            return rowData;
        });

        return convertedList;
    }
}

export default DisplayExpiredPermits;
