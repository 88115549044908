import React, { FC } from 'react';
import ExportToCsvButton from '../../../../Components/Buttons/ExportToCsvButton';
import { IFooterCellData } from '../../../../Components/UI/displayData/Table/Table';

type Props = {
    rows: any[], 
    footer: IFooterCellData[]
}

const BtnExportCsvPermitReport: FC<Props> = ({ rows, footer }) => {

    if (rows.length <= 0) {
        return null;
    }

    const columnsCsv = [
        { id: 'index', displayName: '#' },
        { id: 'loginUser', displayName: 'שם משתמש' },
        { id: 'count', displayName: 'מספר תווים' },
        { id: 'payment', displayName: 'סכום תשלום' },
        { id: 'paymentMethod', displayName: 'סוג תשלום' },
        { id: 'createdBy', displayName: 'נוצר על ידי' }
    ];

    const list = rows.map(permit => {
        return {
            index: permit.index,
            loginUser: permit.loginUser,
            count: permit.count,
            payment: permit.payment,
            paymentMethod: permit.paymentMethod,
            createdBy: permit.createdBy
        }
    });

    return (
        <ExportToCsvButton data={list} headers={columnsCsv} fileName='permit report' />
    );
}

export default BtnExportCsvPermitReport;