import React from 'react';
import FilterExpiredPermits from './components/FilterExpiredPermits';
import DisplayExpiredPermits from './components/DisplayExpiredPermits';
import PageTitle from '../../../Components/pageTitle/PageTitle';
import { useExpiredPermitsPageContext } from '../../../contexts/permit/ExpiredPermitsPageContext';
import DangerAlert from '../../../Components/Alerts/DangerAlert';

const ExpiredPermitsPage = () => {

  const { componentState } = useExpiredPermitsPageContext();
  const { message, hasAlert } = componentState;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1rem' }}>
      <DangerAlert message={message} isShow={hasAlert} />
      <PageTitle title='דוח פגי תוקף' />
      <FilterExpiredPermits />
      <DisplayExpiredPermits />
    </div>
  );
}

export default ExpiredPermitsPage;
