import { useReducer, useEffect, ChangeEvent } from "react";
import { useAuthContextContext } from "../../contexts/AuthContext";
import usePermitApi from '../api/usePermit.api';
import { PermitReportPageActionKind, permitReportReducer } from "../../reducers/permits/permitReportReducer";
import { PaymentMethod, RequestCreatedBy } from '../../types/types.import';
import { initSearchPermitReportFormData } from "../../data/default/searchPermitReportForm.data";
import { useComponentState } from "../useComponentState";
import { formaterMoneyAmount } from "../../Helpers/formaters/numberFormat";
import { IPermitReportRow } from "../../interface/permit/permitReportPage.interface";

export const usePermitReport = () => {

    const { logout } = useAuthContextContext()
    const { getPermitsReportDataApi } = usePermitApi();
    const { componentState, removeAlert, setDangerAlert, setIsLoading, setIsNotLoading } = useComponentState();
    const [dataState, dataDispatch] = useReducer(permitReportReducer, initSearchPermitReportFormData);

    // OnLoading page useEffct 
    useEffect(() => {

        getPermitReportRows();
    }, []);

    async function getPermitReportRows() {

        setIsLoading();

        try {

            const jsonData = await getPermitsReportDataApi(dataState.form);

            const permitReportRows = jsonData.permitReportRows.map((row, index) => {
                return convertRawDataFormServer((index + 1), row);
            });

            const footer = calcTheFooter(permitReportRows.length, jsonData);

            dataDispatch({
                type: PermitReportPageActionKind.UPDATE_DATA,
                payload: {
                    permitReportRows: permitReportRows,
                    totalCardPayment: jsonData.totalCardPayment,
                    totalCashPayment: jsonData.totalCashPayment,
                    totalCitizenCount: jsonData.totalCitizenCount,
                    totalCount: jsonData.totalCount,
                    totalPayment: jsonData.totalPayment,
                    footer: footer
                }
            });

        } catch (error) {

            let message = error?.response?.data?.message

            if (error?.response?.status === 400 || error?.response?.status === 500) {

                console.log(`error, message - ${message}`);
                setDangerAlert('בעיה בשרת, קוד: ' + error?.response?.status);
            }

            if (error?.response?.status === 401 || error?.response?.status === 403) {

                logout();
            }

            console.log(`error - ${error}`);
        }

        setIsNotLoading();
    }

    function calcTheFooter(index: number, jsonData: any): (number | string)[] {

        const totalPayment = formaterMoneyAmount(jsonData.totalPayment);
        const totalCardPayment = formaterMoneyAmount(jsonData.totalCardPayment);
        const totalCashPayment = formaterMoneyAmount(jsonData.totalCashPayment);

        const footer = [
            index,
            'סיכום',
            jsonData.totalCount,
            totalPayment,
            `אשראי: ${totalCardPayment}`,
            `מזומן: ${totalCashPayment}`,
            `קבלת קהל: ${jsonData.totalCount - jsonData.totalCitizenCount} `,
            `אזרח: ${jsonData.totalCitizenCount}`,
        ];

        return footer;
    }

    function convertRawDataFormServer(index: number, row: any): IPermitReportRow {

        let totalPayment = row.payment * row.count;
        let payment = formaterMoneyAmount(row.payment);
        let createdBy = RequestCreatedBy.CITIZEN === row.createdBy ? 'אזרח' : 'קבלת קהל';
        let paymentMethod = 'אין';

        if (row.paymentMethod === PaymentMethod.CARD) paymentMethod = 'אשראי';
        if (row.paymentMethod === PaymentMethod.CASH) paymentMethod = 'מזומן';

        return {
            index: index++,
            loginUser: row.loginUser,
            count: row.count,
            payment: `${totalPayment} (מחיר לתו: ${payment})`,
            paymentMethod: paymentMethod,
            createdBy: createdBy,
        }
    }

    function updateFormInput(event: ChangeEvent<HTMLInputElement>) {

        const name = event.currentTarget.name;
        const value = event.currentTarget.value;

        dataDispatch({
            type: PermitReportPageActionKind.UPDATE_FROM,
            payload: {
                name: name,
                value: value,
            }
        });
    }

    return { getPermitReportRows, dataState, dataDispatch, componentState, removeAlert, updateFormInput };

}