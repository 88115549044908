import React, { FC } from 'react';
import CsvDownloader from 'react-csv-downloader';
import { FileEarmarkSpreadsheet } from 'react-bootstrap-icons';
import { Columns, Datas } from 'react-csv-downloader/dist/esm/lib/csv';

type Props = {
    headers: Columns,
    data: Datas | (() => Datas) | (() => Promise<Datas>) | Promise<Datas>
    fileName?: string,
}

const ExportToCsvButton: FC<Props> = ({ data, headers, fileName = 'csv file' }) => {

    const title = 'יצוא דף לאקסל';
   // const datas = getRowsFunc ? getRowsFunc : data;

    return (
        <CsvDownloader filename={fileName} extension=".csv" separator="," columns={headers} datas={data}>
            <button className="btn btn-success">{title} <FileEarmarkSpreadsheet /></button>
        </CsvDownloader>
    );
}

export default ExportToCsvButton;