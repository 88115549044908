import { ReactNode } from "react";
import translateErrorCode from "../../Helpers/errors/translateErrorCode";
import ResponseStatus from "../../types/responseStatus.types";
import useAxiosPrivate from "../useAxiosPrivate";
import { RequestStatusType } from "../../types/permitRequest.types";
import { IPermitRequestFilterPage, IPermitRequestItemList } from "../../interface/request/permitRequestListPage.interface";
import { IRequest } from "../../interface/request/request.interafe";
import { IComparisonRequestDataAndFiles } from "../../interface/request/comparisonRequestDataAndFiles.interface";
import { initComparisonRequestDataAndFiles } from "../../data/default/requestPermit.data";
import { validateGetRequestsApiResponse } from "../../api/validation/requests/validateGetRequestApiResponse";
import { PermitType } from "../../types/permit.types";

const GET_REQUEST_LIST_API = '/request/get-requests';
const UPDATE_REQUEST_STATUS = '/request/update-status-request';
const APPROVE_REQUEST_BY_ID = '/request/approving-request-by-reqId';
const GET_REQUEST_FULL_DETAILS = '/request';
const APPROVE_REQUEST = '/request/approving-request';
const UPDATE_REQUEST = '/request/updateRequest';
const GET_COMPARING_FILES_WITH_FORM_DATA_RESULT = '/request/get-comparing-files-with-data';
const SEND_GENERAL_MESSAGE = '/request/send-general-message';
const CALCULATE_INIT_PERMIT_DATA = "/request/calculate-init-permit-data";
export const CALCULATE_END_DATE = "/request/calculate-end-date";


export type GetComparisonDataWithFilesResultType = {
    isFailed: boolean,
    message: string,
    comparisonRequestDataAndFiles: IComparisonRequestDataAndFiles
}

export type GetPermitsRequestResultType = {
    status: ResponseStatus,
    message: string | ReactNode,
    data: { count: number, requests: IPermitRequestItemList[] }
}

export type ApproveRequestByReqIdResultType = {
    status: ResponseStatus,
    message: string | ReactNode,
    data: { count: number, requests: any[] }
}

export type SendGeneralMessageResultType = {
    isFailed: boolean,
    message: string | ReactNode,
}

export type GetInitPermitDataApiResultType = {
    status: ResponseStatus,
    message: string | ReactNode,
    data: {
        moneyAmount: number,
        countActivePermitWithSamePersinId: number,
        greatestEndDateOfActivePermit: string,
        estimateEndDate: string,
    }
}

export type CalcDefualtDateApiType = {
    success: boolean,
    message: string,
    date: string,
}


const usePermitRequest = () => {

    const axiosPrivate = useAxiosPrivate();

    async function sendGeneralMessageApi(form: any): Promise<SendGeneralMessageResultType> {

        const result: SendGeneralMessageResultType = {
            isFailed: false,
            message: '',
        }

        const emailVals = form.email;
        const dataSent = { ...form };

        dataSent.email.sendCopyToCity = emailVals.sendCopyToCity === 1;

        try {

            const response = await axiosPrivate.post(SEND_GENERAL_MESSAGE, dataSent);

            const data = response?.data ? response.data : {};

            if (!data?.status || data.status === ResponseStatus.FAILURE) {
                throw Error('הודעה לא נשלחה')
            }

        } catch (error) {

            const errMap = {
                GENERAL_ERROR: 'אימייל לא נשלח, בעיה כללית בשרת',
                BAD_REQUEST: 'אימייל לא נלשח, לא כל הנתונים הגיעו לשרת'
            }

            result.message = errMap.GENERAL_ERROR;

            if (error.response) {

                result.message = translateErrorCode(error, errMap);
            } else if (error.request) {

                console.log(error.request);
            } else { // Something happened in setting up the request that triggered an Error

                console.log('user canceled the request', error.message);
            }
        }

        return result;
    }

    async function getComparisonDataWithFiles(request: IRequest): Promise<GetComparisonDataWithFilesResultType> {

        const result: GetComparisonDataWithFilesResultType = {
            isFailed: false,
            message: '',
            comparisonRequestDataAndFiles: initComparisonRequestDataAndFiles
        }

        const requestId = request.id;


        try {

            const response = await axiosPrivate.get(`${GET_COMPARING_FILES_WITH_FORM_DATA_RESULT}/${requestId}`);

            if (!response.data?.data) {

                result.isFailed = true;
                result.message = 'no data from server!';
                return;
            }

            const responseData = response.data;
            const resultComp = responseData.data;
            const comperingFiles = resultComp.comparingFiles;

            if (responseData.status === ResponseStatus.FAILURE) {

                result.isFailed = true;
                result.message = 'תוכן הקבצים לא נבדק';
                return;
            }

            if (comperingFiles === null) {
                result.isFailed = true;
                result.message = 'תוכן הקבצים לא נבדק';
            }

            result.comparisonRequestDataAndFiles.personId = resultComp.personId ? resultComp.personId : '';
            result.comparisonRequestDataAndFiles.carNum = resultComp.carNum ? resultComp.carNum : '';
            result.comparisonRequestDataAndFiles.fName = resultComp.fName ? resultComp.fName : '';
            result.comparisonRequestDataAndFiles.lName = resultComp.lName ? resultComp.lName : '';
            result.comparisonRequestDataAndFiles.lNameAppearsInPersonId = comperingFiles.lNameInPersonIdFile ? comperingFiles.lNameInPersonIdFile : false;
            result.comparisonRequestDataAndFiles.personIdNumAppearsInCarId = comperingFiles.personIdNumInLicenseFile ? comperingFiles.personIdNumInLicenseFile : false;
            result.comparisonRequestDataAndFiles.personIdNumAppearsInPersonIdCard = comperingFiles.personIdNumInPersonIdFile ? comperingFiles.personIdNumInPersonIdFile : false;
            result.comparisonRequestDataAndFiles.lNameAppearsInCarId = comperingFiles.lNameInLicenseFile ? comperingFiles.lNameInLicenseFile : false;
            result.comparisonRequestDataAndFiles.carNumAppearsInCarId = comperingFiles.carNumInLicenseFile ? comperingFiles.carNumInLicenseFile : false;
            result.comparisonRequestDataAndFiles.fNameAppearsInPersonId = comperingFiles.fNameInPersonIdFile ? comperingFiles.fNameInPersonIdFile : false;
            result.comparisonRequestDataAndFiles.fNameAppearsInCarId = comperingFiles.fNameInLicenseFile ? comperingFiles.fNameInLicenseFile : false;
            result.comparisonRequestDataAndFiles.projectNInAdditionalPerIdFile = comperingFiles.projectNInAdditionalPerIdFile ? comperingFiles.projectNInAdditionalPerIdFile : false;

        } catch (error) {

            console.log('[getPermitRequest] error: ', error);
        }

        return result;
    }

    async function getPermitsRequest(values: IPermitRequestFilterPage, signal = undefined) {

        const controller = new AbortController();

        const result: GetPermitsRequestResultType = {
            status: ResponseStatus.FAILURE,
            message: 'בעיה בשרת',
            data: {
                count: 0,
                requests: [] as IPermitRequestItemList[]
            }
        };

        const data = new FormData();
        data.append('pageNum', String(values.offset));
        data.append('itemPerPage', String(values.limit));
        data.append('from', values.fromDate);
        data.append('to', values.toDate);
        data.append('requestId', String(values.requestDBId));
        data.append('requestCreatedBy', String(values.requestCreatedBy));
        data.append('requestStatus', String(values.requestStatus));
        data.append('personId', values.personId);
        data.append('carNum', values.carNumber);
        data.append('dateTypeToSearchBy', values.dateTypeToSearchBy);

        try {

            if (!signal) {
                signal = controller.signal;
            }

            const response = await axiosPrivate.post(GET_REQUEST_LIST_API, data, { signal: signal });

            const jsonData = response.data;

            if (!validateGetRequestsApiResponse(jsonData)) {

                throw new Error('response from API is not valid!');
            }

            if (jsonData['status'] === ResponseStatus.SUCCESS) {

                result.message = '';
                result.status = ResponseStatus.SUCCESS;
                result.data.count = jsonData.data.count;
                result.data.requests = jsonData.data.requets;
            }

        } catch (error) {

            result.message = 'בעיה כללית בשרת';
            result.status = ResponseStatus.FAILURE;

            if (error.response) {

                result.message = translateErrorCode(error);
            } else if (error.request) {
                console.log(error.request);
            } else { // Something happened in setting up the request that triggered an Error

                result.status = ResponseStatus.SUCCESS;
                result.message = '';
                console.log('user canceled the request', error.message);
            }
        }

        return result;
    }

    async function updateStatus(requestId: number, newStatus: RequestStatusType) {

        const result = {
            status: ResponseStatus.FAILURE,
            message: 'בעיה בשרת',
        }

        const form = new FormData();
        form.append('requestId', String(requestId));
        form.append('newStatus', String(newStatus));

        try {

            const response = await axiosPrivate.post(UPDATE_REQUEST_STATUS, form);
            const data = response.data;

            result.status = data.status;
            result.message = data.message;
        } catch (error) {

            if (error.message === 'Network Error') {

                result.message = 'בעיה כללית בשרת קוד: 502';
            }

            if (error?.response?.status === 401) {

                result.message = 'צריך להתחבר מחדש 401';
            }

            console.log('error: ' + error);
        }

        return result;
    }

    async function approveRequestByReqId(requestId: number) {

        const result: ApproveRequestByReqIdResultType = {
            status: ResponseStatus.FAILURE,
            message: 'בעיה בשרת',
            data: {
                count: 0,
                requests: []
            }
        }

        const data = new FormData();
        data.append('requestId', String(requestId));

        try {

            const response = await axiosPrivate.post(
                APPROVE_REQUEST_BY_ID,
                data
            );

            const jsonData = response.data;

            if (jsonData['status'] !== ResponseStatus.SUCCESS) {
                return;
            }

            result.message = '';
            result.status = ResponseStatus.SUCCESS;

        } catch (error) {

            result.message = 'בעיה כללית בשרת';
            result.status = ResponseStatus.FAILURE;

            if (error.response) {

                result.message = translateErrorCode(error);
            } else if (error.request) {
                console.log(error.request);
            } else { // Something happened in setting up the request that triggered an Error

                result.status = ResponseStatus.SUCCESS;
                result.message = '';
                console.log('user canceled the request', error.message);
            }
        }

        return result;
    }

    async function getPermitRequest(requestId: number) {

        const result = {
            status: ResponseStatus.SUCCESS,
            message: '',
            data: {
                citizenSticker: null,
                endDate: '',
                greatestEndDateOfActivePermit: '',
                exitingPermitWithSamePId: 0,
            }
        };

        try {

            const response = await axiosPrivate.get(`${GET_REQUEST_FULL_DETAILS}/${requestId}`);
            const data = response.data;

            let greatestEndDateOfActivePermit = data.greatestEndDateOfActivePermit ? data.greatestEndDateOfActivePermit : '-1';

            if (greatestEndDateOfActivePermit === '' || greatestEndDateOfActivePermit === '-1') {
                greatestEndDateOfActivePermit = '';
            }

            result.status = ResponseStatus.SUCCESS;
            result.message = '';
            result.data.citizenSticker = data.request;
            result.data.endDate = data.estimateEndDate;
            result.data.greatestEndDateOfActivePermit = greatestEndDateOfActivePermit;
            result.data.exitingPermitWithSamePId = data.countActivePermitWithSamePersinId ? data.countActivePermitWithSamePersinId : 0;

        } catch (error) {

            result.status = ResponseStatus.FAILURE;
            result.message = 'בקשה לא נמצאה';

            if (error?.response) {
                result.message = error?.response.message;
            }

            console.log('[getPermitRequest] error: ', error);
        }

        return result;
    }

    async function approveRequest(values) {

        const form = new FormData();
        const result = { status: '', message: 'success' };

        for (let key in values) {

            let value = values[key]

            if (value === null || value === undefined) continue;

            if (typeof value === 'object' && !Array.isArray(value)) {

                value = JSON.stringify(value);
            }

            form.append(key, value);
        }

        try {

            const response = await axiosPrivate
                .post(APPROVE_REQUEST, form);

            const data = response.data;

            result.status = data.status;
            result.message = data.message
        } catch (error) {

            console.log('error ', error);
            result.status = 'failure';
            result.message = 'בעיה כללית בשרת';

            if (error.message === 'record is valid')
                result.message = 'קיימת כבר בקשה במערכת עם מספר הרכב ותעודת הזהות';
            else if (error.message === 'permit is valid')
                result.message = 'קיים כבר תו בתוקף במערכת עם מספר הרכב ותעודת הזהות';
        }

        return result;
    }

    async function saveRequest(values) {

        const form = new FormData();

        for (let key in values) {

            let value = values[key]

            if (value === null || value === undefined) continue;

            if (typeof value === 'object' && !Array.isArray(value)) {

                value = JSON.stringify(value);
            }

            form.append(key, value);
        }

        let status = 'success';
        let message = 'success';

        try {

            const response = await axiosPrivate
                .post(UPDATE_REQUEST, form);

            const data = response.data;

            status = data.status;
            message = data.message
        } catch (error) {

            console.log('error ' + error);

            message = 'בעיה כללית בשרת';

            if (error.message === 'record is valid')
                message = 'קיימת כבר בקשה במערכת עם מספר הרכב ותעודת הזהות';
            else if (error.message === 'permit is valid')
                message = 'קיים כבר תו בתוקף במערכת עם מספר הרכב ותעודת הזהות';

            status = 'failure';
        }

        return { status: status, message: message };
    }

    async function getInitPermitDataApi(values: {
        permitType: PermitType;
        carNum: string;
        personId: string;
        streetId: string;
        houseNum: string;
    }): Promise<GetInitPermitDataApiResultType> {

        const result: GetInitPermitDataApiResultType = {
            status: ResponseStatus.SUCCESS,
            message: "",
            data: {
                moneyAmount: 0,
                countActivePermitWithSamePersinId: 0,
                greatestEndDateOfActivePermit: "",
                estimateEndDate: "",
            },
        }

        try {

            const response = await axiosPrivate.post(CALCULATE_INIT_PERMIT_DATA, values);
            const data = response.data;

            const moneyAmount = data.moneyAmount ? data.moneyAmount : 0;
            const countActivePermitWithSamePersinId = data.countActivePermitWithSamePersinId ? data.countActivePermitWithSamePersinId : 0;
            const greatestEndDateOfActivePermit = data.greatestEndDateOfActivePermit ? data.greatestEndDateOfActivePermit : '';
            const estimateEndDate = data.estimateEndDate ? data.estimateEndDate : '';

            result.data.moneyAmount = moneyAmount;
            result.data.countActivePermitWithSamePersinId = countActivePermitWithSamePersinId;
            result.data.greatestEndDateOfActivePermit = greatestEndDateOfActivePermit;
            result.data.estimateEndDate = estimateEndDate;
        } catch (error) {

            result.status = ResponseStatus.FAILURE;
            result.message = 'בעיה כללית בשרת';

            if (error.response) {

                result.message = translateErrorCode(error);
            } else if (error.request) {

                console.log(error.request);
            } else { // Something happened in setting up the request that triggered an Error

                console.log('user canceled the request', error.message);
            }
        }


        return result;
    }

    async function calcDefualtDateApi(permitType: number): Promise<CalcDefualtDateApiType> {

        const result: CalcDefualtDateApiType = {
            success: false,
            message: '',
            date: '',
        }

        const body = {
            "permitType": permitType,
            "carNum": '',
            "personId": '',
        };

        try {

            const response = await axiosPrivate.post(CALCULATE_END_DATE, body);

            const data = response.data;

            if (data == null || data.data == null) {
                throw new Error('no data from API');
            }

            if (data.status !== ResponseStatus.SUCCESS) {

                const errorMsg = data?.message ? data.message : 'status from API is: ' + data.status;
                throw new Error(errorMsg);
            }

            if (data.data == null
                || data.data.endDate == null
                || data.data.endDate === '') {

                throw new Error('no data from API');
            }

            result.date = data.data.endDate;

        } catch (error) {

            console.log(error)
        }

        return result;
    }

    return {
        getPermitsRequest, updateStatus, approveRequestByReqId,
        getPermitRequest, approveRequest, saveRequest, getComparisonDataWithFiles,
        sendGeneralMessageApi, getInitPermitDataApi, calcDefualtDateApi
    };

}

export default usePermitRequest;