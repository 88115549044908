import moment from "moment";
import {Languages} from "../../types/types.import";
import { initSearchPermitFormData } from "./searchPermitForm.data";
import { initSearchRequestFormData } from "./searchRequestForm.data";
import { IAppInfoData } from "../../interface/appInfo.interface";
import { initExpiredPermitFormData } from "./expiredPermitForm.data";


export const initAppInfoData: IAppInfoData = {
    languages: Languages.HEBRED,
    formsInitData: {
        requestPermit: {
            updateDate: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
            currentForm: initSearchRequestFormData,
            initForm: initSearchRequestFormData,
        },
        permit: {
            updateDate: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
            currentForm: initSearchPermitFormData,
            initForm: initSearchPermitFormData,
        },
        expiredPermitReport: {
            updateDate: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
            currentForm: initExpiredPermitFormData,
            initForm: initExpiredPermitFormData,
        }
    }
}