import { useState } from "react";
import usePermitRequest from "./api/usePermitRequest.api";

const useEndDateDefualtValInput = () => {

    const { calcDefualtDateApi } = usePermitRequest();
    const [stateData, setStateDate] = useState({
        isLoading: false,
        isFail: false,
        msg: ''
    });

    async function calcDefualtDate(permitType: number) {

        setStateDate({
            isLoading: true,
            isFail: false,
            msg: 'loading end date'
        });

        const response = await calcDefualtDateApi(permitType);

        if (!response.success) {

            setStateDate({
                isLoading: false,
                isFail: true,
                msg: response.message
            });
            
            console.log(response.message);
            return '';
        }

        setStateDate({
            isLoading: false,
            isFail: false,
            msg: 'loaded end date'
        });

        return response.date;
    }

    return { calcDefualtDate, isLoading: stateData.isLoading, isFail: stateData.isFail, msg: stateData.msg };
}

export default useEndDateDefualtValInput;