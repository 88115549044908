import { IPermit } from "../../../interface/permit/permit.interface";

export function isIPermitInterface(permit: any): permit is IPermit {

    if (
        typeof permit.id !== "number" ||
        typeof permit.projectId !== "number" ||
        typeof permit.zone !== "number" ||
        typeof permit.status !== "number" ||
        typeof permit.startValidityDate !== "number" ||
        typeof permit.endValidityDate !== "number" ||
        typeof permit.ownerId !== "number" ||
        typeof permit.payment !== "number" ||
        typeof permit.loginUser !== "string" ||
        typeof permit.statusTav !== "number" || // Assuming PermitStatusType is number
        typeof permit.issueDate !== "number" ||
        typeof permit.approvalDate !== "number" ||
        typeof permit.approveUserId !== "number" ||
        typeof permit.tavGilZahav !== "number" || // Assuming PermitType is number
        typeof permit.agreeDataTransfer !== "number" ||
        typeof permit.agreeToVolunteer !== "number" ||
        typeof permit.paymentMethod !== "string" || // Assuming PaymentMethod is string
        typeof permit.asmachta !== "string" ||
        typeof permit.extra1 !== "string" ||
        typeof permit.extra2 !== "string" ||
        typeof permit.extra3 !== "string" ||
        typeof permit.extra4 !== "string" ||
        typeof permit.extra5 !== "string" ||
        !validateVehicleOwnership(permit.vehicleOwnership) ||
        !validateVehicle(permit.vehicle) ||
        !validateResident(permit.resident)
    ) {
        return false;
    }

    return true;
}

// Helper functions to validate nested structures
export function validateVehicleOwnership(vehicleOwnership: any): boolean {
    return (
        vehicleOwnership &&
        typeof vehicleOwnership.ownerId === "number" &&
        typeof vehicleOwnership.residentId === "number" &&
        typeof vehicleOwnership.fromDate === "number" &&
        typeof vehicleOwnership.toDate === "number" &&
        typeof vehicleOwnership.updateDate === "number" &&
        typeof vehicleOwnership.vehicleId === "number"
    );
}

export function validateVehicle(vehicle: any): boolean {
    return (
        vehicle &&
        typeof vehicle.vehicleId === "number" &&
        typeof vehicle.projectId === "number" &&
        typeof vehicle.vehicleModelId === "number" &&
        typeof vehicle.vehicleMake === "number" &&
        typeof vehicle.vehicleColor === "number" &&
        typeof vehicle.rental === "boolean" &&
        typeof vehicle.plateType === "number" &&
        typeof vehicle.vehicleGroupId === "number" &&
        typeof vehicle.vehicleNum === "string" &&
        typeof vehicle.year === "number"
    );
}

export function validateResident(resident: any): boolean {
    return (
        resident &&
        typeof resident.residentId === "number" &&
        typeof resident.projectId === "number" &&
        typeof resident.firstName === "string" &&
        typeof resident.lastName === "string" &&
        typeof resident.companyName === "string" &&
        typeof resident.personId === "string" &&
        typeof resident.email === "string" &&
        typeof resident.phoneNum === "string" &&
        typeof resident.cellPhone === "string" &&
        typeof resident.ownerTypeId === "number" &&
        typeof resident.stateId === "number" &&
        typeof resident.cityId === "number" &&
        typeof resident.streetId === "number" &&
        typeof resident.houseNum === "string" &&
        typeof resident.appatmentNum === "string" &&
        typeof resident.comments === "string" &&
        typeof resident.companyEntity === "boolean" &&
        typeof resident.zip === "string" &&
        typeof resident.lastSixDigetOfPersonId === "string"
    );
}