import React, { useState, createContext, useContext, useMemo, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { FormikProps, useFormik } from "formik";
import { useProjectDetailsContext } from "../ProjectDetailsContext";
import { addPermitSchema } from "../../validation/permit/addPermitValidation";
import usePermitApi from "../../Hooks/api/usePermit.api";
import useUploadFileModal from "../../features/uploadFile/useUploadFileModal";
import AddPermitPage from "../../Pages/Permits/addPermit/AddPermitPage";
import { initPermitData } from "../../data/default/permit.data";
import { useComponentState } from "../../Hooks/useComponentState";
import { IPermitForm } from "../../interface/permit/permitForm.interface";
import { IComponentState } from "../../interface/IComponentState.interface";
import { IPermitSuccessModal } from "../../interface/permit/addPermitPage.interface";
import { PaymentMethod } from "../../types/paymentMethod.types";
import usePermitRequest from "../../Hooks/api/usePermitRequest.api";
import ResponseStatus from "../../types/responseStatus.types";

type TypeAddPermitPageContext = {
    Formik: FormikProps<IPermitForm>;
    handleChange: (event: React.ChangeEvent<any>) => void;
    clearInput(name: any): void;
    handleAddPermit(event: any): Promise<void>;
    handleCloseModal(url: any): void;
    successModalIsShown: boolean;
    componentState: IComponentState;
    removeAlert: () => void;
    uploadFileModal: boolean;
    closeUploadFileModal(): void;
    openUploadFileModal(): void;
    errorFileModal: string;
    isLoadingFileModal: boolean;
    handleGetInitPermitData(): Promise<void>;
    successModalData: IPermitSuccessModal;
    countActivePermitWithSamePersinId: number;
    setCountActivePermitWithSamePersinId: (count: number) => void;
    greatestEndDateOfActivePermit: string,
    setGreatestEndDateOfActivePermit: (greatestEndDate: string) => void;
}

export const AddPermitPageContext = createContext<TypeAddPermitPageContext | undefined>(undefined);

const AddPermitPageProvider = () => {

    const navigate = useNavigate();
    const { projectDetails } = useProjectDetailsContext();
    const { componentState, removeAlert, setDangerAlert, setIsLoading, setIsNotLoading } = useComponentState();
    const { uploadFileModal, setUploadFileModal, errorFileModal, isLoadingFileModal } = useUploadFileModal();
    const [successModalIsShown, setSuccessModalIsShown] = useState(false);
    const [successModalData, setSuccessModalData] = useState<IPermitSuccessModal>({
        permitId: -1, hasReceipt: false, receiptUrl: ''
    });
    const [countActivePermitWithSamePersinId, setCountActivePermitWithSamePersinId] = useState(0);
    const [greatestEndDateOfActivePermit, setGreatestEndDateOfActivePermit] = useState('');
    const { addPermitApi } = usePermitApi();
    const { getInitPermitDataApi } = usePermitRequest();

    const taveiDayarSiteConfig = projectDetails.taveiDayarSiteConfig;
    const colorAndType = taveiDayarSiteConfig.colorAndType;
    const vColor = colorAndType ? -1 : 164;
    const vType = colorAndType ? -1 : 2096;
    const vehicleGroupName = getPrivateVehicleGroupName();

    const Formik = useFormik<IPermitForm>({
        initialValues: { ...initPermitData, vType: vType, vColor: vColor, vehicleGroupName: vehicleGroupName },
        validateOnMount: true,
        validateOnChange: false,
        validationSchema: addPermitSchema,
        onSubmit: () => { },
    });

    const { values, setFieldValue, validateForm, setFieldTouched } = Formik;

    const handleChange = useMemo(() => {
        return (event) => {
            setFieldValue(event.target.name, event.target.value)
        };
    }, []);

    useEffect(() => {
        handleGetInitPermitData();
    }, [values.permitType]);

    async function handleGetInitPermitData() {

        setIsLoading();
        const streetIdStr = values.street.toString();

        const sendingBody = {
            permitType: values.permitType,
            carNum: values.carNumber,
            personId: values.personId,
            streetId: streetIdStr ? streetIdStr : '-1',
            houseNum: values.houseNum ? values.houseNum : '1'
        }

        const response = await getInitPermitDataApi(sendingBody);

        if (response.status === ResponseStatus.FAILURE) {

            setDangerAlert(response.message);
            return;
        }

        const moneyAmount = response.data.moneyAmount;
        const countActivePermitWithSamePersinId = response.data.countActivePermitWithSamePersinId;
        const greatestEndDateOfActivePermit = response.data.greatestEndDateOfActivePermit;
        const estimateEndDate = response.data.estimateEndDate;

        if (estimateEndDate) {
            setFieldValue('endValidityDate', estimateEndDate);
        }

        setFieldValue('amount', moneyAmount);

        if (moneyAmount <= 0) {
            setFieldValue('paymentMethod', PaymentMethod.NONE);
        } else {
            setFieldValue('paymentMethod', PaymentMethod.CARD);
        }

        setCountActivePermitWithSamePersinId(countActivePermitWithSamePersinId);
        setGreatestEndDateOfActivePermit(greatestEndDateOfActivePermit);

        setIsNotLoading();
    }

    async function handleAddPermit(event: any) {

        event.preventDefault();
        setIsLoading();

        // 1. checking validation.
        const jsonErrors = await validateForm();

        const errorName = Object.keys(jsonErrors);

        if (errorName.length > 0) {

            errorName.forEach(name => {
                setFieldTouched(name, true);
            });

            setIsNotLoading();
            return;
        }

        const response = await addPermitApi(values);

        if (response.status === ResponseStatus.FAILURE) {

            setDangerAlert(response.message);
            return;
        }

        const permitId = response.data.permitId;
        const hasReceipt = response.data.hasReceipt;
        const receiptUrl = response.data.receiptUrl;

        setIsNotLoading();

        setSuccessModalData({ permitId: permitId, hasReceipt: hasReceipt, receiptUrl: receiptUrl });
        setSuccessModalIsShown(true);
    }

    function handleCloseModal(url) {

        setSuccessModalIsShown(false);
        navigate(url);
    }

    function closeUploadFileModal() {
        setUploadFileModal(false);
    }

    function openUploadFileModal() {
        setUploadFileModal(true);
    }

    function getPrivateVehicleGroupName() {

        const vehicleGroupList = projectDetails.vehicleGroups;

        const val = vehicleGroupList.find(v => v.vehicleGroupName === '&#1508;&#1512;&#1496;&#1497;');

        return val ? val.id : -1;
    }

    function clearInput(name) {
        setFieldValue(name, '');
        setFieldTouched(name, true);
    }

    return (
        <AddPermitPageContext.Provider value={{
            handleChange, Formik, clearInput,
            handleAddPermit, handleCloseModal, successModalIsShown, componentState, removeAlert,
            uploadFileModal, closeUploadFileModal, openUploadFileModal,
            errorFileModal, isLoadingFileModal, handleGetInitPermitData, successModalData,
            countActivePermitWithSamePersinId, setCountActivePermitWithSamePersinId, greatestEndDateOfActivePermit, setGreatestEndDateOfActivePermit
        }}>
            <AddPermitPage />
        </AddPermitPageContext.Provider>
    );
}

export default AddPermitPageProvider;

export const useAddPermitPageContext = () => useContext(AddPermitPageContext);