import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';
import { AuthProvider } from './contexts/AuthContext';
import { ProjectDetailsProvider } from './contexts/ProjectDetailsContext';
import { AppInfoProvider } from './contexts/AppInfoContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ProjectDetailsProvider>
      <AuthProvider>
        <AppInfoProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </AppInfoProvider>
      </AuthProvider>
    </ProjectDetailsProvider>
  </React.StrictMode>
);
