import moment from "moment";
import { AppFormNames, IAppInfoData } from "../interface/appInfo.interface";
import { IPermitFilterPage } from "../interface/permit/permitListPage.interface";
import { IPermitRequestFilterPage } from "../interface/request/permitRequestListPage.interface";
import { IExpiredPermitPageForm } from "../interface/permit/expiredPermitPage.interface";

export enum AppInfoActionKind {
    CHENGE_LANG = 'CHENGE_LANG',
    UPDATE_INIT_FORM = 'UPDATE_INIT_FORM',
}

type APP_INFO_ACTION = {
    type: AppInfoActionKind.CHENGE_LANG,
    payload: {
        languages: any,
    }
}
    | {
        type: AppInfoActionKind.UPDATE_INIT_FORM,
        payload: {
            formName: AppFormNames,
            formData: IPermitRequestFilterPage | IPermitFilterPage | IExpiredPermitPageForm,
        }
    };

export const appInfoReducer = (state: IAppInfoData, action: APP_INFO_ACTION) => {

    switch (action.type) {
        case AppInfoActionKind.CHENGE_LANG:

            return { ...state, languages: action.payload.languages };
        case AppInfoActionKind.UPDATE_INIT_FORM:

            return {
                ...state,
                formsInitData: {
                    ...state.formsInitData,
                    [action.payload.formName]: {
                        ...state.formsInitData[action.payload.formName],
                        updateDate: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                        currentForm: action.payload.formData
                    }
                }
            };
        default:
            return state;
    }
} 