import React, { useMemo } from 'react';
import { useFormik } from 'formik';
import { useAppInfoContext } from '../../contexts/AppInfoContext';
import FormLogin from './components/FormLogin';
import Style from './LoginPage.module.css';
import { initDammyLoginData, initLoginData } from '../../data/default/login.data';
import { loginSchema } from '../../validation/loginValidation';
import LoadingModal from '../../Components/LoadingModal/LoadingModal';
import DangerAlert from '../../Components/Alerts/DangerAlert';
import useLogin from '../../Hooks/useLogin';
import { ILoginForm } from '../../interface/loginPage.interface';


const LoginPage = () => {

    const { screenWidth } = useAppInfoContext();
    const { componentState, removeAlert, loginAndLoadSysTables } = useLogin();
    const { isLoading, message, hasAlert } = componentState;

    const Formik = useFormik<ILoginForm>({
        initialValues: initLoginData,
        validateOnMount: true,
        validateOnChange: false,
        validationSchema: loginSchema,
        onSubmit: (values) => handleLogin(values),
    });

    const handleChange = useMemo(() => {
        return (event) => {
            Formik.setFieldValue(event.target.name, event.target.value);
        };
    }, []);

    return (
        <>
            {isLoading && <LoadingModal />}

            <div className={Style.loginPageLayout}>
                <DangerAlert isShow={hasAlert} message={message} onClose={removeAlert} />
                <h1 className={Style.pageTitle}>לולהטק -  כניסה לניהול תווים</h1>
                <div className={Style.formContainer}>
                    <div className={Style.formTitle}>פרטי התחברות</div>
                    <FormLogin Formik={Formik} handleChange={handleChange} componentState={componentState} />
                </div>
            </div>
        </>
    );

    async function handleLogin(values: ILoginForm) {
        const r = await loginAndLoadSysTables(values);
    }
}

export default LoginPage;