import React, { createContext, useContext, useEffect, useMemo, useReducer, useRef, useState } from "react";
import { FormikProps, useFormik } from "formik";
import { useAppInfoContext } from "../AppInfoContext";
import { PermitListPageKind, listPermitsReducer } from "../../reducers/permits/listPermitsReducer";
import { useProjectDetailsContext } from "../ProjectDetailsContext";
import PermitsListPage from "../../Pages/Permits/managmentPermits/PermitsListPage";
import { convPermitRowRawDataFromServerCsv } from "../../Helpers/conversion/permit/convPermitRowRawDataFromServer";
import { initSearchPermitFormData } from "../../data/default/searchPermitForm.data";
import { useComponentState } from "../../Hooks/useComponentState";
import { ResponseStatus } from "../../types/types.import";
import { IComponentState } from "../../interface/IComponentState.interface";
import usePermitApi from "../../Hooks/api/usePermit.api";
import { IPermitFilterPage, IPermitListPageData } from "../../interface/permit/permitListPage.interface";
import { AppFormNames } from "../../interface/appInfo.interface";

type TypePermitsListPageContext = {
    handleChange: (event: any) => void,
    handlerUpdatePermitStatus(status?: number, permitId?: number): Promise<void>,
    componentState: IComponentState,
    Formik: FormikProps<IPermitFilterPage>,
    handlerSubmit: any,
    permitListPageData: IPermitListPageData,
    changePage: any,
    showChangeStatusModal: any,
    closeChangeStatusModal: any,
    openChangeStatusModal: any,
    permitToEditStatus: any,
    setPermitToEditStatus: any,
    getRowForCsv: any,
    handleOnClickAdvSearch: any,
    refHistoryModal: React.RefObject<any>,
}


export const PermitsListPageContext = createContext<TypePermitsListPageContext | undefined>(undefined);

const PermitsListPageProvider = () => {

    const { projectDetails } = useProjectDetailsContext();
    const { componentState, setIsLoading, setIsNotLoading, setDangerAlert } = useComponentState();
    const { updateInitForm, getCurrentInitForm } = useAppInfoContext();
    const [permitListPageData, permitListPageDataDispatch] = useReducer(listPermitsReducer, { permits: [], totalCount: 0, });
    const [showChangeStatusModal, setShowChangeStatusModal] = useState(false);
    const [permitToEditStatus, setPermitToEditStatus] = useState(null);
    const { getPermitsApi, updateStatusPermitApi } = usePermitApi();
    const refHistoryModal = useRef(null);

    const streetsMap = projectDetails.streets;
    const typesMap = projectDetails.types;
    const colorsMap = projectDetails.colors;
    const vehicleGroupsMap = projectDetails.vehicleGroups;

    const Formik = useFormik<IPermitFilterPage>({
        initialValues: { ...getCurrentInitForm(AppFormNames.permit, {}) },
        onSubmit: values => {
            setFieldValue('offset', 0);
            handlerSubmit();
        }
    });

    const { values, handleSubmit, setFieldValue } = Formik;

    const handleChange = useMemo(() => {
        return (event) => {
            setFieldValue(event.target.name, event.target.value)
        };
    }, []);

    useEffect(() => {

        const controller = new AbortController();
        const signal = controller.signal;

        handlerGetInitPermit(signal);

        return () => {
            console.log("clean up func");
            controller.abort();
        }
    }, []);

    return (
        <PermitsListPageContext.Provider value={{
            handleChange, handlerUpdatePermitStatus,
            componentState,
            Formik,
            handlerSubmit, permitListPageData, changePage,
            showChangeStatusModal, closeChangeStatusModal, openChangeStatusModal,
            permitToEditStatus, setPermitToEditStatus, getRowForCsv, handleOnClickAdvSearch,
            refHistoryModal
        }}>
            <PermitsListPage />
        </PermitsListPageContext.Provider>
    );

    async function getRowForCsv() {

        setIsLoading();
        const response = await getPermitsApi(values, 0, -1);
        let convertedList = [];

        if (response.status === ResponseStatus.FAILURE) {

            setDangerAlert(response.message);
            return [];
        }

        const list = response.data.permits;

        if (list && list.length > 0) {

            convertedList = list.map((permit, index) => convPermitRowRawDataFromServerCsv(permit,
                colorsMap, typesMap, streetsMap, vehicleGroupsMap));
        }

        setIsNotLoading()
        return convertedList;
    }

    function changePage(offset: number) {

        setFieldValue('offset', offset);
        const valuesTemp = { ...values, offset: offset };
        updateInitForm(AppFormNames.permit, valuesTemp);
        handlerSubmit(offset);
    }

    function closeChangeStatusModal() {
        setShowChangeStatusModal(false);
    }

    function openChangeStatusModal(permit) {

        setPermitToEditStatus(permit);
        setShowChangeStatusModal(true);
    }

    async function handlerGetInitPermit(signal: AbortSignal = undefined) {

        setIsLoading();

        updateInitForm(AppFormNames.permit, values);

        const response = await getPermitsApi(values, 0, values.limit, signal);

        if (response.status === ResponseStatus.FAILURE) {

            setDangerAlert(response.message);
            return;
        }

        const list = response.data.permits;
        const count = response.data.count;

        permitListPageDataDispatch({
            type: PermitListPageKind.UPDATE_PRMIT_LIST,
            payload: { totalCount: count, permits: list }
        });

        setIsNotLoading();
    }

    async function handlerSubmit(offset = 0) {

        setIsLoading();

        updateInitForm(AppFormNames.permit, values);

        const response = await getPermitsApi(values, offset, values.limit);

        if (response.status === ResponseStatus.FAILURE) {

            setDangerAlert(response.message);
            return;
        }

        const list = response.data.permits;
        const count = response.data.count;

        permitListPageDataDispatch({
            type: PermitListPageKind.UPDATE_PRMIT_LIST,
            payload: { totalCount: count, permits: list }
        });

        setIsNotLoading();
    }

    async function handlerUpdatePermitStatus(status = -1, permitId = -1) {

        closeChangeStatusModal();
        setIsLoading();

        const response = await updateStatusPermitApi(permitId, status);

        if (response.status === ResponseStatus.FAILURE) {

            setDangerAlert(response.message);
            return;
        }

        handleSubmit();
        setIsNotLoading();
    }

    function handleOnClickAdvSearch(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {

        event.preventDefault();
        
        const isAdvancedSearchOpen = !values.isAdvancedSearchOpen;
        setFieldValue('isAdvancedSearchOpen', isAdvancedSearchOpen);

        if(isAdvancedSearchOpen) {
            return;
        }

        setFieldValue('zone', initSearchPermitFormData.zone);
        setFieldValue('status', initSearchPermitFormData.status);
        setFieldValue('loginUser', initSearchPermitFormData.loginUser);
        setFieldValue('streetId', initSearchPermitFormData.streetId);
        setFieldValue('houseNum', initSearchPermitFormData.houseNum);
        setFieldValue('appatmentNum', initSearchPermitFormData.appatmentNum);
        setFieldValue('firstName', initSearchPermitFormData.firstName);
        setFieldValue('lastName', initSearchPermitFormData.lastName);
        setFieldValue('vehicleColorId', initSearchPermitFormData.vehicleColorId);
        setFieldValue('vehicleGroupId', initSearchPermitFormData.vehicleGroupId);
        setFieldValue('vehicleMakerId', initSearchPermitFormData.vehicleMakerId);
    }
}

export default PermitsListPageProvider;

export const usePermitsListPageContext = () => useContext(PermitsListPageContext);