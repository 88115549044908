import { useState } from "react";
import ResponseStatus from "../../types/responseStatus.types";
import useFiles from "../../Hooks/api/useFiles.api";

const useUploadFileModal = () => {

    const { uploadFileToServerApi } = useFiles();
    const [uploadFileModal, setUploadFileModal] = useState(false);
    const [isLoadingFileModal, setIsLoadingFileModal] = useState(false);
    const [errorFileModal, setErrorFileModal] = useState('');

    async function uploadFileToServer(personId: string, file: any) {
        setIsLoadingFileModal(true);

        const response = await uploadFileToServerApi(personId, file);
  
        setIsLoadingFileModal(false);

        if (response.status === ResponseStatus.FAILURE) {
            setErrorFileModal(response.message);
        } else {
            setUploadFileModal(false);
        }

        return response;
    }

    return { uploadFileModal, setUploadFileModal, uploadFileToServer, errorFileModal, isLoadingFileModal };
}

export default useUploadFileModal;