import React, { FC, MouseEvent, ReactNode, useState } from 'react';
import { CiCircleCheck } from "react-icons/ci";
import Style from './listOfRows.module.css';

const COPY_MESSAGE = 'העתק';
const HAS_COPIED_MESSAGE = 'הועתק';

type Props = {
    label: string,
    value: string | number | ReactNode,
    canCopyToClipboard?: boolean,
}

const RowCardItem: FC<Props> = ({ label, value, canCopyToClipboard }) => {

    const style: React.CSSProperties = {};
    const onClick = canCopyToClipboard ? copyToClipboard : null;
    const [isCopied, setIsCopied] = useState(false);
    const [isHover, setIsHover] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });


    if (canCopyToClipboard) {
        style.position = 'relative';
        style.cursor = 'pointer';
    }

    return (
        <div
            onMouseLeave={handleOnMouseLeave}
            onMouseEnter={handleOnMouseEnter}
            style={style}
            onClick={onClick}
            className={Style.rowCardItem}
        >
            <div onMouseMove={handleOnMouseMove} className={Style.rowCardItemLabel}>
                {label}:
            </div>

            <div onMouseMove={handleOnMouseMove} className={Style.rowCardItemValue}>
                {value}
            </div>

            <div className='alert alert-secondary' style={{
                display: isHover ? 'block' : 'none',
                padding: '0.25rem',
                fontSize: '1.12rem',
                position: 'absolute',
                zIndex: '30',
                top: `${position.y}pt`,
                right: `${position.x}pt`,
            }}>
                {isCopied ? (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                        {HAS_COPIED_MESSAGE}
                        <CiCircleCheck color='green' size={25} />
                    </div>
                ) : COPY_MESSAGE}
            </div>
        </div>
    );

    function handleOnMouseEnter(event: MouseEvent<HTMLDivElement>) {

        if (!canCopyToClipboard) return;

        setIsHover(true);
    }

    function handleOnMouseLeave(event: MouseEvent<HTMLDivElement>) {

        if (!canCopyToClipboard) return;

        setIsHover(false);
        setIsCopied(false);
    }

    function handleOnMouseMove(event: MouseEvent<HTMLDivElement>) {

        if (!canCopyToClipboard) return;

        // Get the target
        const target = event.target;

        // Get the bounding rectangle of target
        //@ts-ignore
        const rect = target.getBoundingClientRect();

        // Mouse position
        const x = (rect.right - event.clientX) + 5;
        const y = (event.clientY - rect.top) + 25;

        setPosition({ x: x, y: y });
    }

    function copyToClipboard() {

        let valueToCopy = value.toString();

        // Copy the text inside the text field
        navigator.clipboard.writeText(valueToCopy);

        // Alert the copied text
        setIsCopied(true);
    }
}

export default RowCardItem;