import { ISystemTableColor, ISystemTableStreet, ISystemTableVehicleGroup, ISystemTableVehicleMakers } from "../../interface/projectConfig/projectConfig.interface";
import { decodeNCRStringIfNeeded } from "../language";


export function convertColorCodeToText(code: number, colors: ISystemTableColor[], decodeToHeb: boolean = false): string {

    if (colors == null) return '';

    if (!Array.isArray(colors)) return '';

    const arr = colors.filter(color => color.id === code);

    if (arr.length === 0) return '';

    return decodeToHeb ? decodeNCRStringIfNeeded(arr[0].name) : arr[0].name;
}

export function convertMakerCodeToText(code: number, makers: ISystemTableVehicleMakers[], decodeToHeb: boolean = false): string {

    if (makers == null) return '';

    if (!Array.isArray(makers)) return '';

    const arr = makers.filter(maker => maker.id === code);

    if (arr.length === 0) return '';

    return decodeToHeb ? decodeNCRStringIfNeeded(arr[0].name) : arr[0].name;
}

export function convertStreetCodeToText(code: number, streets: ISystemTableStreet[]): string {

    if (streets == null) return '';

    if (!Array.isArray(streets)) return '';

    const arr = streets.filter(street => street.id === code);

    if (arr.length === 0) return '';

    return arr[0].name;
}

export function convertVehicleGroupCodeToText(code: number, vehicleGroups: ISystemTableVehicleGroup[], decodeToHeb: boolean = false): string {

    if (vehicleGroups == null) return '';

    if (!Array.isArray(vehicleGroups)) return '';

    const arr = vehicleGroups.filter(street => street.id === code);

    if (arr.length === 0) return '';

    return decodeToHeb ? decodeNCRStringIfNeeded(arr[0].vehicleGroupName) : arr[0].vehicleGroupName;
}