import { ReactNode } from "react";
import useAxiosPrivate from "../useAxiosPrivate";
import translateErrorCode from "../../Helpers/errors/translateErrorCode";

export const GET_PAYMENTS_REPORT = '/payments/get-payment-report-stats';

type PaymentReportApiType = {
    success: boolean,
    message: string | ReactNode,
    pRecordsChartAreaTemp: any[],
}

export const useReportApi = () => {

    const axiosPrivate = useAxiosPrivate();

    async function paymentReportApi(from: string, to: string, signal = undefined) {

        const controller = new AbortController();
        const withAbortSig = signal ? true : false;

        if (!withAbortSig) {
            signal = controller.signal;
        }

        const dataToSend = {
            from: from,
            to: to
        };

        const result: PaymentReportApiType = {
            success: false,
            message: '',
            pRecordsChartAreaTemp: [],
        }

        try {

            const response = await axiosPrivate.post(GET_PAYMENTS_REPORT, dataToSend, { signal: signal });
            const data = response.data;

            if(!validatePaymentReportApiRawDataFromServer(data.paymentsRecordsGroupByDate)) {

                result.message = 'הנתונים לא הגיעו בצורה תקינה';
                return result;
            }

            const pRecordsChartAreaTemp = convertRawDataFromServerToChartArea(data.paymentsRecordsGroupByDate);

            result.success = true;
            result.pRecordsChartAreaTemp = pRecordsChartAreaTemp;

        } catch (error) {

            let message: string | ReactNode = 'בעיה כללית בשרת';

            if (error.response) {

                message = translateErrorCode(error);
            } else if (error.request) {
                console.log(error.request);
            } else { // Something happened in setting up the request that triggered an Error

                message = error.message;
                console.log('user canceled the request', error.message);
            }

            result.message = message;
        }

        return result;
    }


    return {
        paymentReportApi,
    }

    function validatePaymentReportApiRawDataFromServer(data: any): boolean {

        if (!data || !Array.isArray(data)) return false;

        for (const item of data) {

            if (typeof item.dateAndTime !== "string" ||
                typeof item.cashAmount !== "number" ||
                typeof item.cashNumberOfUnits !== "number" ||
                typeof item.creditCardAmount !== "number" ||
                typeof item.creditCardNumberOfUnits !== "number"
            ) { return false; }
        }

        return true;
    }

    function convertRawDataFromServerToChartArea(datas: any[]) {

        if (!datas || !Array.isArray(datas)) return [];

        return datas.map(data => {
            return {
                name: data.dateAndTime,
                cashAmount: data.cashAmount,
                cashNumberOfUnits: data.cashNumberOfUnits,
                creditCardAmount: data.creditCardAmount,
                creditCardNumberOfUnits: data.creditCardNumberOfUnits,
            }
        });
    }
}