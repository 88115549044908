import React, { FC, ReactNode } from 'react';
import NoDataFoundWithFilter from '../NoDataFoundWithFilter';

export interface IRowData {
    [name: string]: any,
    key: string,
}

export interface IHeaderData {
    label: any,
    key: string,
    name: string,
    colspan?: number,
}

export interface IFooterCellData {
    label: any,
    key: string,
    colspan?: number,
}

type TableType = {
    headers: IHeaderData[],
    rows: IRowData[],
    footers?: IFooterCellData[],
    withIndex?: boolean,
}

type TrType = {
    children: ReactNode
}

type TableCellType = {
    value: any,
    scope: 'col' | 'row',
    isTh?: boolean,
    colspan?: number,
}

const Tr: FC<TrType> = ({ children }) => {

    return <tr>{children}</tr>;
}

const TableCell: FC<TableCellType> = ({
    value, scope,
    isTh = false,
    colspan = 1,
}) => {

    return isTh ? <th colSpan={colspan} scope={scope}>{value}</th> : <td colSpan={colspan}>{value}</td>;
}

const Table: FC<TableType> = ({
    headers,
    rows,
    footers = [],
    withIndex = true,
}) => {


    if (headers.length === 0 || rows.length === 0) {
        return <NoDataFoundWithFilter />;
    }

    const header = buildHeader(headers, withIndex);

    const body = buildBody(rows, headers, withIndex);

    const footer = buildFooter(footers, withIndex);


    return (
        <table className="table table-striped">
            <thead>
                <Tr>
                    {header}
                </Tr>
            </thead>
            <tbody>
                {body}
            </tbody>
            <tfoot>
                <Tr>
                    {footer}
                </Tr>
            </tfoot>
        </table>
    );


    function buildHeader(headers: IHeaderData[], withIndex: boolean) {

        let header = headers.map((header, index) => {

            const isFirst = !withIndex && index < 1;
      
            return <TableCell scope={isFirst ? 'row' : 'col'} key={header.key} value={header.label} isTh={true} />;
        });

        if(withIndex) {

            header = [
                <TableCell scope={'row'} key={'header-index-0'} value={'#'} isTh={true} />
                , ...header
            ];
        }

        return header;
    }

    function buildFooter(footers: IFooterCellData[], withIndex: boolean) {

        const footer = footers.map((cell, index) => {

            const isFirst = index < 1;
            
            return <TableCell scope={isFirst ? 'row' : 'col'} key={cell.key} value={cell.label} isTh={isFirst} />;
        });

        return footer;
    }

    function buildBody(rowsData: IRowData[], headers: IHeaderData[], withIndex: boolean) {

        let body = [];
  
        try {
            
            body = rowsData.map((row, indexRow) => {
                
                const arr = [];
                const rowKey = row.key;

                if(withIndex) {

                    arr.push(<TableCell scope={'row'} key={`body-${indexRow}-cell-0`} value={indexRow + 1} isTh={true} />);
                }

                headers.forEach((header, index) => {

                    const val = row[header.name];
                    const cellKey = `${rowKey}-cell-${index}`;
                    const isTh = (index < 1) && !withIndex;
                    arr.push(<TableCell scope={isTh ? 'row' : 'col'} key={cellKey} value={val} isTh={isTh} />);
                });

                return <Tr key={rowKey}>{arr}</Tr>;
            });
        } catch (error) {

            console.log(error);
        }

        return body;
    }
}

export default Table;