import React, { FC, ReactNode } from 'react';
import PagingBox from '../PagingBox/PagingBox';
import Table, { IFooterCellData, IHeaderData, IRowData } from './Table';

type TypeTableWithPaging = {
    headers: IHeaderData[],
    rows: IRowData[],
    paigingData: any,
    footers?: IFooterCellData[],
    name?: string,
    handleChengeLimit?: (event: React.ChangeEvent<HTMLSelectElement>) => void,
}

const TableWithPaging: FC<TypeTableWithPaging> = ({
    rows,
    headers,
    paigingData,
    footers = [] as IFooterCellData[],
    name = 'שורות',
    handleChengeLimit = null,
}) => {

    let table: string | ReactNode = 'אין נתונים';

    const count = Array.isArray(rows) ? rows.length : 0;

    const offset = paigingData.offset;
    const limit = paigingData.limit;
    const totleCount = paigingData.totleCount;
    const changePage = paigingData.changePage;
    const isLoading = paigingData.isLoading;

    if (count > 0) table = <Table headers={headers} rows={rows} footers={footers} />

    return (
        <React.Fragment>
            <PagingBox
                offset={offset}
                limit={limit}
                count={totleCount}
                changePage={changePage}
                name={name}
                isLoading={isLoading}
                handleChengeLimit={handleChengeLimit}
            />
            <Table headers={headers} rows={rows} footers={footers} />
            {(count > 5) ? (
                <PagingBox
                    handleChengeLimit={() => { }}
                    offset={offset}
                    limit={limit}
                    count={totleCount}
                    name={name}
                    changePage={changePage}
                    isLoading={isLoading} 
                />
            ) : ''}
        </React.Fragment>
    );
}

export default TableWithPaging;