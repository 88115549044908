import React, { useMemo } from 'react';
import { usePaymentsReportPageContext } from '../../../../contexts/reports/PaymentsReportPageContext';
import ChartPaymentPie from './ChartPaymentPie';
import TablePie from './TablePie';

const ContainerSummary = () => {

    const { pRecordsChartArea } = usePaymentsReportPageContext();

    const pRecordsDataSummary = useMemo(() => {
        return expensiveCalculation(pRecordsChartArea);
    }, [pRecordsChartArea]);

    return (
        <div style={{
            display: 'flex', flexDirection: 'column', height: "35rem", padding: '2rem', backgroundColor: 'white',
            borderRadius: '4px', boxShadow: "0px 5px 20px 0px rgba(0,0,0,0.1)"
        }}>
            <div style={{ flexGrow: 1 }}>
                <TablePie data={pRecordsDataSummary} />
            </div>
            <div style={{ flexGrow: 1 }}>
                <ChartPaymentPie data={pRecordsDataSummary} />
            </div>
        </div>
    );

    function expensiveCalculation(pRecordsChartArea) {

        let totalCashNumberOfUnits = 0;
        let totalCreditCardNumberOfUnits = 0;
        let totalCashAmount = 0;
        let totalCardAmount = 0;

        pRecordsChartArea.forEach(pRecordChart => {
            totalCashNumberOfUnits += pRecordChart.cashNumberOfUnits
            totalCashAmount += pRecordChart.cashAmount
            totalCardAmount += pRecordChart.creditCardAmount
            totalCreditCardNumberOfUnits += pRecordChart.creditCardNumberOfUnits
        });

        return {
            totalCashNumberOfUnits, totalCreditCardNumberOfUnits,
            totalCashAmount, totalCardAmount
        };
    }
}

export default ContainerSummary;