import React, { createContext, useContext, useEffect, useState } from "react";
import moment from "moment";
import PaymentReportPage from "../../Pages/reports/paymentsReport/PaymentReportPage";
import { useComponentState } from "../../Hooks/useComponentState";
import { IComponentState } from "../../interface/IComponentState.interface";
import { useReportApi } from "../../Hooks/api/useReport.api";

type TypePaymentsReportPageContext = {
    fromDate: string,
    setFromDate: (fromDate: string) => void,
    toDate: string,
    setToDate: (toDate: string) => void,
    componentState: IComponentState,
    removeAlert: () => void,
    handleSearch(signal?: any): Promise<void>,
    displayByPieChart: number,
    setDisplayByPieChart: (num: number) => void,
    pRecordsChartArea: any[]
}


export const PaymentsReportPageContext = createContext<TypePaymentsReportPageContext | undefined>(undefined);

const PaymentsReportPageProvider = () => {

    const { paymentReportApi } = useReportApi();
    const { componentState, removeAlert, setDangerAlert, setIsLoading, setIsNotLoading } = useComponentState();
    const [fromDate, setFromDate] = useState(moment().format('YYYY-MM-DD'));
    const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
    const [pRecordsChartArea, setPRecordsChartArea] = useState([]);
    const [displayByPieChart, setDisplayByPieChart] = useState(1);

    useEffect(() => {

        const controller = new AbortController();
        const signal = controller.signal;
        handleSearch(signal);

        return () => {
            console.log("clean up func");
            controller.abort();
        }
    }, []);

    async function handleSearch(signal: AbortSignal = undefined) {

        setIsLoading();

        const response = await paymentReportApi(fromDate, toDate, signal);

        if (!response.success) {

            setDangerAlert(response.message);
            return;
        }

        setPRecordsChartArea(response.pRecordsChartAreaTemp);
        setIsNotLoading();
        removeAlert();
    }

    return (
        <PaymentsReportPageContext.Provider value={{
            fromDate, setFromDate, toDate, setToDate, componentState, removeAlert, handleSearch,
            displayByPieChart, setDisplayByPieChart, pRecordsChartArea
        }}>
            <PaymentReportPage />
        </PaymentsReportPageContext.Provider>
    );
}

export default PaymentsReportPageProvider;

export const usePaymentsReportPageContext = () => useContext(PaymentsReportPageContext);