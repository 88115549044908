import React, { useState } from 'react';
import { createContext, useContext, useEffect } from "react";
import { FormikProps, useFormik } from "formik";
import ExpiredPermitsPage from "../../Pages/Permits/expiredPermitsPage/ExpiredPermitsPage";
import { initExpiredPermitData, initExpiredPermitFormData } from "../../data/default/expiredPermitForm.data";
import { expiredPermitSchema } from "../../validation/permit/expiredPermitValidation";
import { useComponentState } from "../../Hooks/useComponentState";
import { IExpiredPermitPageData, IExpiredPermitPageForm } from '../../interface/permit/expiredPermitPage.interface';
import { IComponentState } from "../../interface/IComponentState.interface";
import usePermitApi from '../../Hooks/api/usePermit.api';
import { IPermit } from '../../interface/permit/permit.interface';
import { useAppInfoContext } from '../AppInfoContext';
import { AppFormNames } from '../../interface/appInfo.interface';

type TypeExpiredPermitsPageContext = {
    Formik: FormikProps<IExpiredPermitPageForm>;
    handleGetExpiredPermits(values: IExpiredPermitPageForm, offset?: number): Promise<void>;
    getExpiredPermitsForCsv(values: IExpiredPermitPageForm): Promise<IPermit[]>;
    expiredPermitsPage: IExpiredPermitPageData;
    componentState: IComponentState;
    changePage: (offset: number) => void;
}

export const ExpiredPermitsPageContext = createContext<TypeExpiredPermitsPageContext | undefined>(undefined);

const ExpiredPermitsPageProvider = () => {

    const { updateInitForm, getCurrentInitForm } = useAppInfoContext();
    const { componentState, setDangerAlert, setIsLoading, setIsNotLoading } = useComponentState();
    const { getExpiredPermitsApi } = usePermitApi();
    const [expiredPermitsPage, setExpiredPermitsPage] = useState(initExpiredPermitData);

    const Formik = useFormik<IExpiredPermitPageForm>({
        initialValues: { ...getCurrentInitForm(AppFormNames.expiredPermitReport, {}) },
        validateOnMount: true,
        validateOnChange: false,
        onSubmit: values => {
            handleGetExpiredPermits(values);
        },
        validationSchema: expiredPermitSchema,
    });

    const { values } = Formik;

    useEffect(() => {
        handleGetExpiredPermits(values);
    }, []);

    return (
        <ExpiredPermitsPageContext.Provider value={{ Formik, componentState, handleGetExpiredPermits, getExpiredPermitsForCsv, expiredPermitsPage, changePage }}>
            <ExpiredPermitsPage />
        </ExpiredPermitsPageContext.Provider>
    );

    async function handleGetExpiredPermits(values: IExpiredPermitPageForm, offset: number = -1) {

        setIsLoading();
        const valuesToSend = { ...values };

        if (offset > -1)
            valuesToSend.offset = offset;

        
        updateInitForm(AppFormNames.expiredPermitReport, valuesToSend);
        
        const response = await getExpiredPermitsApi(valuesToSend);

        if (!response.isSuccess) {

            setDangerAlert(response.message);
            return;
        }

        const permits = response.data.permits;
        const count = response.data.count;

        setExpiredPermitsPage({ permits: permits, count: count });
        setIsNotLoading();
    }

    async function getExpiredPermitsForCsv(values: IExpiredPermitPageForm) {

        const valuesToSend = { ...values, offset: -1, limit: -1 };

        const response = await getExpiredPermitsApi(valuesToSend);

        if (!response.isSuccess) {

            setDangerAlert(response.message);
            return [] as IPermit[];
        }

        return response.data.permits;
    }

    function changePage(offset: number) {

        Formik.setFieldValue('offset', offset);
        const valuesTemp = { ...values, offset: offset };
        updateInitForm(AppFormNames.expiredPermitReport, valuesTemp);
        handleGetExpiredPermits(values, offset);
    }

}

export default ExpiredPermitsPageProvider;

export const useExpiredPermitsPageContext = () => useContext(ExpiredPermitsPageContext);