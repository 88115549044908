import React, { ChangeEvent, FC } from 'react';
import PagingBox from '../PagingBox/PagingBox';
import NoDataFoundWithFilter from '../NoDataFoundWithFilter';

type ListOfRowsType = {
    rows: any[],
    name: string,
    paigingData: any,
    handleChenge: (event: ChangeEvent<HTMLSelectElement>) => void,
}

const ListOfRows: FC<ListOfRowsType> = ({ rows, name, paigingData, handleChenge }) => {

    let count = 0;
    if (Array.isArray(rows)) count = rows.length;

    const offset = paigingData.offset;
    const limit = paigingData.limit;
    const totleCount = paigingData.totleCount;
    const changePage = paigingData.changePage;
    const isLoading = paigingData.isLoading;

    return (
        <div>
            <PagingBox
                offset={offset}
                limit={limit}
                count={totleCount}
                changePage={changePage}
                name={name}
                isLoading={isLoading}
                handleChengeLimit={handleChenge} 
            />

            {count > 0 ? rows : <NoDataFoundWithFilter />}
            
            {(count > 5) ? <PagingBox offset={offset} limit={limit} count={totleCount} changePage={changePage} isLoading={isLoading} handleChengeLimit={handleChenge} /> : ''}
        </div>
    );
}

export default ListOfRows;